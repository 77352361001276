@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

:root {
  --theme-color-black: #0b1220;
  --theme-color-white: #ffffff;
  --theme-color-red: #ff0000;
  --theme-color-body-bg: #0d0d0d;
  --theme-color-text: #ffffff;
  --theme-color-primary: #0b44cb;
  --theme-color-landing-bg: rgba(255, 255, 255, 0.58);
  --theme-color-landing-img-bg: rgba(0, 0, 0, 0.58);
  --theme-color-img-bg: #333333;
  --theme-color-topmenu-text: #b4b7bb;
  --theme-color-sidemenu-text: #b4b7bb;
  --theme-color-sidemenu-hover-text: #2c2d31;
  --theme-color-sidemenu-focused-text: #b4b7bb;
  --theme-color-sidemenu-focused-bg: #2c2d31;
  --theme-color-input-bg: #dbdbdb;
  --theme-color-input-text: #b4b7bb;
  --theme-color-transparent: rgba(0, 0, 0, 0);
  --theme-color-image-shadow: rgba(50, 255, 232, 0.75);
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  display: none;
}
*:focus {
  outline: none;
}

body {
  background-color: var(--theme-color-body-bg);
  color: var(--theme-color-text);
  font-family: 'Roboto', sans-serif !important;
  padding: 0px !important;
  margin: 0px !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
div,
button,
li {
  font-family: 'Roboto', sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
button,
li {
  color: var(--theme-color-text);
}
a,
a:hover {
  opacity: 1 !important;
}
a.link {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.empty-container {
  width: 1920px !important;
  height: 1080px !important;
  overflow: hidden !important;
}
.splashscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader .loader-text {
  width: 195px;
  font-size: 40px;
  line-height: 48px;
  color: var(--theme-color-primary);
  font-weight: 600;
}
.loader .loader-text .dots {
  width: 10px;
  height: 10px;
  font-size: 10px;
  line-height: 10px;
  background-color: var(--theme-color-primary);
  border-radius: 100%;
  margin-right: 5px;
}
.loader .loader-text .dots.dot-dot1 {
  margin-left: 5px;
}

.live-video-container {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.bg-player-overlay-opacity {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.58);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.app-container {
  width: 1920px !important;
  height: 1080px !important;
  /* padding-left: 60px; */
  /* padding-right: 60px; */
  /* margin: 0px auto; */
  overflow: hidden;
}

.top-navigation {
  position: fixed;
  left: 60px;
  right: 60px;
  top: 38px;
  height: 100px;
  background-color: none !important;
  z-index: 8888;
}
.top-navigation .nav-left,
.top-navigation .nav-right {
  flex: auto;
}
.top-navigation .brand-logo img {
  max-width: none;
  max-height: none;
  margin-right: 30px;
}
.top-navigation .tabs .menu-item {
  font-size: 28px;
  line-height: 48px;
  font-weight: 600;
  border-bottom: 6px solid var(--theme-color-transparent);
  color: var(--theme-color-topmenu-text);
}
.top-navigation .tabs .menu-item.active {
  color: var(--theme-color-primary);
}

.top-navigation .tabs .menu-item.focused,
.top-navigation .tabs .menu-item:hover {
  border-bottom: 6px solid var(--theme-color-primary);
}
.top-navigation .icon {
  border-bottom: 6px solid var(--theme-color-transparent);
}
.top-navigation .icon.active img {
  filter: drop-shadow(0px 0px var(--theme-color-primary));
}
.top-navigation .icon.focused,
.top-navigation .icon:hover {
  border-bottom: 6px solid var(--theme-color-primary);
}
.top-navigation .icon img {
  margin-left: 15px;
  margin-right: 15px;
}

.page-container {
  display: flex;
  height: 980px;
  /* width: 100%; */
  width: 1965px;
  overflow: hidden;
  overflow: hidden;
  z-index: 9999;
  /* top: -7px; */
  top: 28px;
  position: fixed;
  margin-top: 790px;
  left: -46px;
}
.page-container.has-show-bg-video {
  transition: margin-top 0.5s ease-in-out;
}
.page-container.main-no-video-show {
  margin-top: 115px !important;
}
.page-container .side-menu {
  width: 380px;
  top: 237px;
  /* padding-left: 60px; */
  padding-left: 145px;
  justify-content: flex-start;
}
.page-container .page-content {
  width: 100%;
  justify-content: flex-end;
  padding-left: 20px;
  padding-right: 20px;
}
.page-container .side-menu,
.page-container .page-content {
  flex: auto;
}
.page-container .side-menu .side-nav a {
  width: 380px;
  height: 85px;
  align-items: center;
  display: flex;
  color: var(--theme-color-sidemenu-text) !important;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 5px;
  font-weight: 600;
  padding-left: 24px;
  padding-right: 10px;
}
.page-container .side-menu .side-nav a.active,
.page-container .side-menu .side-nav a.focused {
  background-color: var(--theme-color-sidemenu-focused-bg);
  color: var(--theme-color-sidemenu-focused-text) !important;
}
.page-container .side-menu .side-nav a:hover {
  background-color: var(--theme-color-primary);
  color: var(--theme-color-sidemenu-hover-text) !important;
}
.page-container .side-menu .side-nav a span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-container .page-content.has-bg-video {
  /* padding-top: 540px; */
  /* margin-top: 672px; */
  height: 1476px;
  /* padding-right: 92px; */
}

.settings-container {
  display: flex;
  margin-top: 150px;
  padding-left: 80px;
  padding-right: 80px;
}
.settings-qrcode {
  justify-content: flex-start;
  height: 350px;
  width: 350px;
}
.settings-section {
  justify-content: flex-end;
  margin-left: 50px;
}
.settings-section .title {
  width: 550px;
  font-size: 28px;
  line-height: 44px;
  margin-top: 80px;
  margin-bottom: 30px;
}
.settings-section .link {
  font-size: 36px;
  line-height: 48px;
  color: var(--theme-color-primary);
}

.horizontal-list {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}
.horizontal-list .grid-title {
  position: absolute;
  font-size: 30px;
  line-height: 42px;
  /* top: 16px; */
  top: 12px;
  font-weight: 600;
  margin-left: 115px;
}
.horizontal-list .media-scroller {
  display: flex;
  overflow-x: auto;
  /* width: 1855px; */
  /* width: 1899px; */
  width: 1943px;
  padding-right: 96px;
  /* padding-bottom: 49px; */
  /* margin-left: -13px; */
  position: relative;
  left: 2px;
}
.media-element.prj-element {
  padding-right: 68px !important;
}
.horizontal-list .media-element {
  position: relative;
  /* width: 570px; */
  padding: 40px 115px;
  /* margin-right: 20px; */
  margin-right: 43px;
}
.horizontal-list .media-element.portrait {
  position: relative;
  margin-top: 20px;
  width: 292px;
  height: 438px;
}
.horizontal-list .media-element .img {
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.horizontal-list .media-element.portrait .img {
  width: 292px;
  height: 438px;
}
.horizontal-list .media-element .img .img-container {
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-img-bg);
}
.horizontal-list .media-element.portrait .img .img-container {
  width: 292px;
  height: 438px;
}
.horizontal-list .media-element .img .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.horizontal-list .media-element.portrait .img .img-container img {
  inline-size: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
}
.horizontal-list .media-element.active .img,
.horizontal-list .media-element.focused .img,
.horizontal-list .media-element:hover .img {
  box-shadow: 0px 0px 10px 10px var(--theme-color-image-shadow);
}
.horizontal-list .media-element .title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
  padding: 0px !important;
  margin: 0px;
}
.media-element.landscape.prj-element .img {
  width: 560px;
  height: 315px;
}
.media-element.landscape.prj-element .img .img-container {
  width: 560px;
  height: 315px;
}
.horizontal-list .media-element .sub-title {
  font-size: 19px;
  width: 532px;
  line-height: 28px;
  padding: 0px !important;
  margin: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-list {
  width: 100%;
  margin-left: 377px;
}
.grid-list .media-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 50px;
  /* margin-left: 75px; */
  margin-left: 220px;
  position: absolute;
}
.side-menu {
  position: fixed !important;
}
.grid-list .media-element {
  position: relative;
  width: 570px;
  margin-right: 60px;
  margin-bottom: 60px;
}
.grid-list .media-element .img {
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 5px solid var(--theme-color-transparent);
  overflow: hidden;
}
.grid-list .media-element .img .img-container {
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-img-bg);
}
.grid-list .media-element .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.grid-list .media-element.active .img,
.grid-list .media-element.focused .img,
.grid-list .media-element:hover .img {
  border: 5px solid var(--theme-color-primary);
  box-shadow: 0px 0px 10px 10px var(--theme-color-image-shadow);
}
.grid-list .media-element .title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
  padding: 0px !important;
  margin: 0px !important;
}
.grid-list .media-element .sub-title {
  font-size: 24px;
  line-height: 28px;
  padding: 0px !important;
  margin: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.media-element .img .video-duration {
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: right;
  width: 560px;
  font-size: 30px;
  color: var(--theme-color-white);
  padding-right: 38px;
  padding-bottom: 15px;
}
.media-element .img .progress-wrapper {
  position: absolute;
  bottom: 5px;
  left: 1%;
  width: 98%;
}
.media-element .img .progress-wrapper .progress-bar {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0px;
  margin: 0px auto;
}
.media-element .img .progress-wrapper .progress-bar-fill {
  display: block;
  height: 5px;
  background-color: var(--theme-color-primary);
}

.fullscreen-container-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--theme-color-body-bg);
  z-index: 88888;
}
.fullscreen-container-fixed .bg-poster {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(20px);
  -webkit-filter: blur(20px);
}
.fullscreen-container-fixed .bg-poster-layer {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.fullscreen-container-fixed .back-to-page {
  position: relative;
  top: 30px;
  left: 100px;
  color: var(--theme-color-white);
  cursor: pointer;
  display: none;
}
.fullscreen-container-fixed .video-details {
  position: relative;
  display: flex;
  flex-direction: row;
  /* margin-top: 30px;
  margin-bottom: 40px; */
  /* transition: margin-top 1s ease-in-out; */
  margin-top: 188px;
  margin-bottom: -70px;
  height: 711px;
  scroll-behavior: smooth;
}
.fullscreen-container-fixed .video-details .details {
  width: 65%;
  padding-top: 109px;
  padding-left: 100px;
}
.fullscreen-container-fixed .video-details .details .title {
  font-size: 61px;
  line-height: 78px;
  font-weight: 500;
  margin-top: 10px;
}
.fullscreen-container-fixed .video-details .details .description {
  font-size: 25px;
  line-height: 28px;
  margin-top: 10px;
  height: 170px;
}
.fullscreen-container-fixed .video-details .details .buttons {
  /* margin-top: 50px; */
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 850px;
}
.fullscreen-container-fixed .video-details .details .buttons .play-btn {
  background-color: var(--theme-color-white);
  color: var(--theme-color-body-bg);
  margin-top: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  font-size: 30px;
  width: 400px;
  height: 96px;
  border-radius: 16px;
}
.fullscreen-container-fixed .video-details .details .buttons .play-btn:hover {
  background-color: var(--theme-color-primary);
  color: var(--theme-color-body-bg);
}
.fullscreen-container-fixed .video-details .details .buttons .play-btn img {
  margin-right: 15px;
  width: 45px;
  height: 45px;
}
.fullscreen-container-fixed .video-details .image {
  width: 35%;
  padding-right: 100px;
  text-align: right;
}
.fullscreen-container-fixed .video-details .image-wrap {
  width: 485px;
  height: 296px;
  background-color: var(--theme-color-img-bg);
  border-radius: 20px;
  float: right;
  overflow: hidden;
  margin-top: 84px;
}
.fullscreen-container-fixed .video-details .image-wrap.portrait {
  width: 400px;
  height: 600px;
  margin-top: 0px;
}
.fullscreen-container-fixed .video-details .image .image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fullscreen-container-fixed .more-like-this {
  position: relative;
  width: 100%;
  /* padding-left: 93px; */
  top: 86px;
}
.fullscreen-container-fixed .more-like-this .title {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  /* margin-bottom: 20px; */
  margin-left: 95px;
}

.fullscreen-container-fixed .more-like-this .list {
  display: flex;
  overflow-x: auto;
  margin-left: -30px;
  height: 543px;
  padding: 0 128px;
}
.fullscreen-container-fixed .more-like-this .list .video {
  position: relative;
  min-width: 526px;
  max-width: 526px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.fullscreen-container-fixed .more-like-this .list .video.portrait {
  /* min-width: 400px; */
  /* height: 600px; */
  min-width: 300px;
  height: 450px;
  margin-right: 20px;
}
.fullscreen-container-fixed .more-like-this .list .video:hover {
  border: 5px solid var(--theme-color-primary);
}
.fullscreen-container-fixed .more-like-this .list .video img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.video-container {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 9999;
}
.video-container #video-player,
.video-container #video-player .video-js {
  width: 100%;
  height: 100%;
}
.video-container .close-video-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99999;
  background-color: var(--theme-color-white);
  padding: 5px 10px;
  display: none;
}
.video-container .close-video-container:hover {
  background-color: var(--theme-color-white);
  opacity: 1;
}
.video-container .close-video-container img {
  width: 18px;
}

.search-bar {
  width: 80%;
  height: 60px;
  /* margin: 0px auto; */
  margin: 14px auto;
}
.search-bar input {
  height: 60px;
  line-height: 60px;
  font-size: 30px !important;
  color: var(--theme-color-input-text);
  background-color: var(--theme-color-input-bg);
  border: 0px !important;
}
.search-results {
  padding: 20px 0px 50px 0px;
  width: 100%;
}
.search-results .searching {
  width: 100%;
  text-align: center;
  color: var(--theme-color-text);
  font-size: 28px;
  margin-top: 250px;
}
.search-results .loading {
  margin-top: 250px;
}
.video-player-screen {
  height: 1080px !important;
  width: 1920px !important;
}

.player-overlay {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}

.player-bottom-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 20px;
  height: 205px;
}

.player-text {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-self: baseline;
}

.player-watching-text {
  font-size: 30px;
  margin-bottom: 5px;
}

.player-title {
  font-size: 38px;
  margin-bottom: 15px;
}

.player-progress-track {
  display: flex;
  width: 1550px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 25px;
}

.player-timer {
  display: flex;
  color: #ffffff;
  font-size: 25px;
  margin-top: 30px;
  align-self: baseline;
  align-items: center;
}

.player-time {
  display: flex;
  color: #ffffff;
  font-size: 21px;
  margin: 0px 20px;
  width: 90px;
}

.player-progress-bar {
  background-color: #ffffff;
  border-radius: 25px;
}

.player-button-group {
  display: flex;
  flex-direction: row;
  align-self: center;
  position: absolute;
  width: 1550px;
  justify-content: center;
}

.media-btn {
  background-color: transparent;
}

.media-btn.focused {
  background-color: var(--theme-color-primary);
}
.rewind.media-btn,
.playpause,
.fastforward {
  display: flex;
  align-items: center;
}

.media-btn-img {
  height: 40px;
  width: 40px;
  margin: 10px 20px;
}
.vjs-progress-control.vjs-control {
  display: none;
}
.vjs-control-bar {
  display: none !important;
}
button#resume-btn.play-btn.prj-element.focused {
  background-color: var(--theme-color-primary);
}
button#play-btn.play-btn.prj-element.focused {
  background-color: var(--theme-color-primary);
}
div.video.prj-element {
  border: 12px solid transparent !important;
}
div.video.prj-element.focused img {
  box-shadow: 0px 0px 10px 10px var(--theme-color-image-shadow);
}
.player-overlay {
  background-color: none !important;
}
.image-wrap.landscape {
  width: 560px;
  height: 315px;
}
.video.prj-element.landscape {
  width: 560px;
  height: 315px;
}
.image-wrap.landscape {
  width: 560px !important;
  height: 315px !important;
}
.video.prj-element.landscape {
  width: 560px !important;
  height: 315px !important;
  min-width: 560px !important;
}
video.prj-element.landscape img {
  width: 560px !important;
  min-width: 560px !important;
  height: 315px !important;
}
.video-details-main-div {
  overflow: hidden;
  height: 100%;
}
.media-element.landscape.prj-element {
  padding-top: 59px;
  padding-bottom: 0;
  /* padding-left: 374px; */
  padding-right: 0;
  margin-right: -123px;
}
.list-container {
  display: flex;
  /* padding: 20px; */
}
.movies {
  height: 530px;
}
div.video.prj-element img:hover {
  box-shadow: 0px 0px 10px 10px var(--theme-color-image-shadow);
}
.side-nav .prj-element.focused {
  background-color: var(--theme-color-primary) !important;
  color: var(--theme-color-sidemenu-hover-text) !important;
}
/* .side-nav .prj-element.active{
  background-color: var(--theme-color-sidemenu-focused-bg)!important;
} */
.side-nav .prj-element.focused span {
  color: var(--theme-color-sidemenu-hover-text) !important;
}
.video-rating {
  font-size: 28px;
  margin-bottom: 20px;
}
.play-btn.small.prj-element.focused {
  width: 185px !important;
}
.play-btn.small.prj-element {
  width: 185px !important;
}
.grid-list .media-element.landscape.prj-element {
  margin-right: 75px !important;
}
.overlay-grid {
  position: absolute;
  transition: 0.5s ease;
  /* height: 300px; */
  /* width: 304px; */
  width: 560px;
  height: 129px;
  bottom: 0;
  left: -3px;
  /* background-color: #0404045e; */
  background: linear-gradient(to bottom, transparent 0%, black 100%);
  opacity: 1;
}
/* .video-details-main-div .title{
  margin-left: 95px;
} */
.page-container.search-page {
  margin-top: 135px !important;
}
.video.media-element.prj-element {
  padding-top: 19px !important;
}
.video.prj-element.media-element.portrait {
  padding-top: 0px !important;
}
.more-like-this-horizontal-list {
  padding-right: 1px;
}
.video.prj-element.media-element {
  /* padding: 0 0 !important; */
  /* padding-left: 50px!important; */
}
.more-like-this-list-container {
  position: relative;
  left: -102px;
}
.loader.loader-fix-pos {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  /* margin-top: -159px; */
  position: fixed;
  top: 97px;
  left: -13px;
  /* padding: 20px; */
  /* background: red; */
}
.more-like-this .horizontal-list .media-element.portrait {
  margin-top: 12px !important;
}
.video-js .vjs-play-progress {
  background-color: var(--theme-color-primary);
}
.title.detail-page-title {
  margin: 0 0 0 5px !important;
  font-size: 20px !important;
}
